var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"payment-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.t('tooltip.filters'))+" ")]),_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('v-select',{attrs:{"items":_vm.statusOptions,"item-text":"text","item-value":"value","single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.t('select.status')},on:{"change":function($event){return _vm.updateFilter('views-fanaty-payment-list', 'status', _vm.statusFilter)}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"invoice-list-status",attrs:{"placeholder":_vm.t('transactions.from_date'),"prepend-icon":_vm.icons.mdiCalendar,"outlined":"","readonly":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.fromDateComputed),callback:function ($$v) {_vm.fromDateComputed=$$v},expression:"fromDateComputed"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFrom),callback:function ($$v) {_vm.modalFrom=$$v},expression:"modalFrom"}},[_c('v-date-picker',{attrs:{"color":"primary","max":_vm.dateTo ? _vm.dateTo : _vm.maxDate},on:{"input":function($event){_vm.modalFrom = false},"change":function($event){return _vm.updateFilter('views-fanaty-payment-list', 'dateFrom', _vm.dateFrom)}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"invoice-list-status",attrs:{"placeholder":_vm.t('transactions.to_date'),"prepend-icon":_vm.icons.mdiCalendar,"outlined":"","readonly":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.toDateComputed),callback:function ($$v) {_vm.toDateComputed=$$v},expression:"toDateComputed"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalTo),callback:function ($$v) {_vm.modalTo=$$v},expression:"modalTo"}},[_c('v-date-picker',{attrs:{"color":"primary","max":_vm.maxDate,"min":_vm.dateFrom},on:{"input":function($event){_vm.modalTo = false},"change":function($event){return _vm.updateFilter('views-fanaty-payment-list', 'dateTo', _vm.dateTo)}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),_c('v-divider'),_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.computedTableColumns,"items":_vm.listTable,"loading":_vm.loading,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]},"items-per-page":-1,"search":_vm.searchQuery,"sort-desc":_vm.descSort,"options":_vm.options,"server-items-length":_vm.totalListTable},on:{"update:search":function($event){_vm.searchQuery=$event},"update:sortDesc":function($event){_vm.descSort=$event},"update:sort-desc":function($event){_vm.descSort=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",style:(_vm.$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de'),attrs:{"to":{ name: 'views-payment-preview', params: { id: item.id, dataParams: item, pageParams: _vm.options, filterParams: _vm.filters } }}},[_vm._v(" #"+_vm._s(item.id)+" ")])]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatStdDate(item.payment_datetime_str, 'datetime-custom', _vm.configObjFacility.is12Hour, _vm.$i18n.locale)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveClientAvatarVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveClientAvatarVariant(item.status)}},[_vm._v(" "+_vm._s(item.status_str)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[(_vm.hasPermission(9))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"info","to":{ name: 'views-fanaty-payment-preview', params: { id: item.id, dataParams: item, pageParams: _vm.options, filterParams: _vm.filters } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Payment")])]):_vm._e(),(_vm.hasPermission(76))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.onPrint(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t('tooltip.print_receivet')))])]):_vm._e()],1)]}}],null,true)})],1),_c('vue-html2pdf',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideHtml2pdf),expression:"!hideHtml2pdf"}],ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"enable-links":true,"preview-modal":true,"paginate-elements-by-height":1400,"filename":_vm.computedPaymentData.id ? ("Receipt #" + (_vm.computedPaymentData.id) + " " + (_vm.computedPaymentData.payment_date)) : 'Receipt',"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%","html-to-pdf-options":{
      html2canvas: {
        scrollX: 0,
        scrollY: 0,
      },
    }},on:{"beforeDownload":function($event){return _vm.beforeDownload()},"hasDownloaded":function($event){return _vm.hasDownloaded()}}},[_c('section',{staticStyle:{"background-color":"#ffffff","width":"100%","height":"100%"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('payment-history-report',{attrs:{"data-params":_vm.computedPaymentData,"has-transaction-video":_vm.hasTransactionVideo}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }